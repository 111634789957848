import React, { createContext, useState, useMemo } from 'react';
export const InterviewContext = createContext();

export const InterviewProvider = ({ children }) => {
    const [topic, setTopic] = useState('');
    const [data, setData] = useState(null);
    const [answers, setAnswers] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [evaluation, setEvaluation] = useState(null);
    const [analysis, setAnalysis] = useState(null);

    const value = useMemo(() => ({
        topic,
        setTopic,
        data,
        setData,
        answers,
        setAnswers,
        questions,
        setQuestions,
        evaluation,
        setEvaluation,
        analysis,
        setAnalysis
    }), [topic, setTopic, data, setData, answers, setAnswers, questions, setQuestions, evaluation, setEvaluation, analysis, setAnalysis]);

    return (
        <InterviewContext.Provider value={value}>
            {children}
        </InterviewContext.Provider>
    );
};
