import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import notify from "../utils/notify";
import { Loader } from ".";

import RecordWebCamContext from "../context/WebcamContext";
import { InterviewContext } from "../context/InterviewContext";

import { Radio, RadioGroup } from "react-styled-radio";
import styled from "styled-components";
import ToggleSwitch from "./ToggleSwitch";

const Input = styled.input`
  width: auto; 
  font-size: 16px;
  padding: 5px;
  margin: 0;
  background-color: #f2f2f2;
  border: none;
  border-radius: 3px;
  ::focus {
    border: none;
  }
  ::placeholder {
    color: #6c757d;
  }
`;

const FromGroup = styled.div`
  font-size: 16px;
  padding: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  label {
    margin-bottom: 5px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 991px) {
    flex-direction: column;
  }
`;

const Col = styled.div`
 &.buttonAction {
   display: flex;
   width:100%;
   
  }
.label {
  margin-right:20px;
}

.difficulty {
    display: flex;
    flex-direction: column;
     @media screen and (max-width: 991px) { 
       margin-bottom:20px;
      }
    .label {
      margin-bottom:10px;
    }
    
  }
.button-action {
 display: flex;
 justify-content: center;
 margin-top: 7px;
  width:100%;
   button {
    background-color: #4500F4;
    color: #fff;
    font-size: 18px;
    border-radius: 25px;
    border: none;
    min-width: 190px;
    min-height: 43px;
    -webkit-box-shadow: 6px 6px 6px 0px rgba(0,0,0,0.16);
    -moz-box-shadow: 6px 6px 6px 0px rgba(0,0,0,0.16);
    box-shadow: 6px 6px 6px 0px rgba(0,0,0,0.16);
    cursor: pointer;
}
`;

const TabsBlock = ({ setData }) => {

  const [job_description, setjob_description] = useState("");
  const [first_name, setfirst_name] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [loading, setLoading] = useState(false);
  const [recordVideo, setRecordVideo] = useState(false);
  const navigate = useNavigate();
  const recordWebcam = useContext(RecordWebCamContext);
  const { topic, setTopic, questions, setQuestions } = useContext(InterviewContext);


  useEffect(() => {
    if (recordVideo) {
      recordWebcam.open();
      recordWebcam.start();
      setQuestions([]);
    }
    else recordWebcam.close();
    // eslint-disable-next-line
  }, [recordVideo]);

  const handleInputChange = async (event) => {
    const inputText = event.target.value;
    setTopic(inputText);

  };

  const handleSubmitTechnicalInterview = async (e) => {
    e.preventDefault();
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach((track) => track.stop());
    } catch (error) {
      window.alert('Please turn on your microphone to use this feature.');
    }

    setLoading(true);
    try {
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/tech-interview`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          topic: topic,
          num_questions: questions,
          difficulty: difficulty,
        }),
      })
      const data = await res.json();
      if (data !== null) {
        setData(data);
        setLoading(false);
        navigate("/questions");
      }
      else throw new Error("Internal Server Error");
    }
    catch (err) {
      notify("Internal Server Error", "error");
      setLoading(false);
    }
  };

  const handleSubmitBehavioralInterview = async (e) => {
    e.preventDefault();
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      stream.getTracks().forEach((track) => track.stop());
    } catch (error) {
      window.alert('Please turn on your microphone to use this feature.');
    }
    setLoading(true);
    try {
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/behavioral`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          job_description: job_description,
          first_name: first_name,
          no_of_questions: questions,
        }),
      })
      localStorage.setItem("topic", topic)
      const datas = res.json();
      if (datas !== null) {
        setData(datas);
        setLoading(false);
        navigate("/questions");
      }
      else throw new Error("Internal Server Error");
    }
    catch (err) {
      notify("Internal Server Error", "error");
      setLoading(false);
    }

  };

  return (
    <div className="tabs">
      <Tabs>
        <Tab label="Technical">
          <div>
            <form onSubmit={handleSubmitTechnicalInterview}>
              <FromGroup>
                <label htmlFor="topic">Enter Topic</label>
                <Input
                  type={"text"}
                  id="topic"
                  value={topic}
                  onChange={handleInputChange}
                ></Input>
              </FromGroup>
              <FromGroup>
                <label htmlFor="questions">Number of Questions</label>
                <Input
                  type={"number"}
                  id="questions"
                  value={questions}
                  onChange={(e) => setQuestions(e.target.value)}
                ></Input>
              </FromGroup>
              <Row>
                <Col>
                  <div className="difficulty">
                    <label htmlFor="difficulty-level" className="label">Difficulty Level</label>
                    <RadioGroup
                      horizontal
                      name="difficulty"
                      value={difficulty}
                      onChange={(e) => setDifficulty(e.target.value)}
                    >
                      <Radio small value="beginner" label="Easy" />
                      <Radio small value="intermediate" label="Medium" />
                      <Radio small value="advanced" label="Advanced" />
                    </RadioGroup>
                  </div>
                </Col>
                <Col>
                  <div className="mt-8">
                    <label htmlFor="video-on" className="label">Video Playback</label>
                    <ToggleSwitch
                      id="video-on"
                      name="record"
                      checked={recordVideo}
                      onChange={(checked) => setRecordVideo(checked)}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="buttonAction">
                  <div className="button-action flex flex-col justify-center items-center space-y-3">
                    <button
                      type="submit"
                      disabled={!topic || !questions || !difficulty}
                    >
                      Start Interview
                    </button>
                    {loading && <Loader />}
                  </div>
                </Col>
              </Row>
            </form>
          </div>
        </Tab>


        {/* abehavioral Intervidew */}
        <Tab label="Behavioral">
          <div>
            <form onSubmit={handleSubmitBehavioralInterview}>
              <FromGroup>
                <label htmlFor="job-description">Job Description</label>
                <Input
                  type={"text"}
                  id="job-description"
                  placeholder="please input your Job description here"
                  value={job_description}
                  onChange={(e) => setjob_description(e.target.value) || handleInputChange}
                ></Input>
              </FromGroup>
              <FromGroup>
                <label htmlFor="questions">Number of Questions</label>
                <Input
                  type={"number"}
                  id="questions"
                  value={questions}
                  onChange={(e) => setQuestions(e.target.value)}
                ></Input>
              </FromGroup>
              <Row>
                <Col>

                </Col>
                <Col>
                  <label htmlFor="video-on" className="label">Video Playback</label>
                  <ToggleSwitch
                    id="video-on"
                    name="record"
                    checked={recordVideo}
                    onChange={(checked) => setRecordVideo(checked)}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="buttonAction">
                  <div className="button-action flex flex-col justify-center items-center space-y-3">
                    <button type="submit" disabled={!job_description || !questions}>
                      Start Interview
                    </button>
                    {loading && <Loader />}
                  </div>
                </Col>
              </Row>
            </form>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

const Tabs = (props) => {
  const [activeTab, setActiveTab] = useState(props.children[0].props.label);
  const changeTab = (button) => {
    setActiveTab(button);
  };
  let content;
  let buttons = [];
  return (
    <div>
      {React.Children.map(props.children, (child) => {
        buttons.push(child.props.label);
        if (child.props.label === activeTab) content = child.props.children;
      })}

      <TabButtons
        activeTab={activeTab}
        buttons={buttons}
        changeTab={changeTab}
      />
      <div className="tab-content">{content}</div>
    </div>
  );
};

const TabButtons = ({ buttons, changeTab, activeTab }) => {
  return (
    <div className="tab-buttons">
      {buttons.map((button) => {
        return (
          <button
            key={button}
            className={button === activeTab ? "active" : ""}
            onClick={() => {
              localStorage.setItem("tab", button);
              changeTab(button);
            }}
          >
            {button}
          </button>
        );
      })}
    </div>
  );
};

const Tab = (props) => {
  return <React.Fragment>{props.children}</React.Fragment>;
};

export default TabsBlock;