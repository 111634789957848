import React, { useContext } from "react";

import styled from "styled-components";
import logoimg from "./../assets/logo.png";
import Information from "./../assets/information.png";
import Questions from "./../assets/questions.png";
import Said from "./../assets/said.png";
import RecordWebCamContext from "../context/WebcamContext";
import { InterviewContext } from '../context/InterviewContext';
import TabsBlock from "../components/TabsBlock";

const Wrapper = styled.div`
  width: auto;
  min-height: auto;
  background: #f0ebf8;
  box-shadow: inset 2px -10px 5px 0px rgb(0 0 0 / 11%);
  margin: -2.5rem 3rem;
  border-radius: 50px;
`;

const Logo = styled.div`
  width: 200px;
  height: 60px;
  background-color: #fff;
  border-radius: 0 40px 40px 0;
  top: -20px;
  position: relative;
  img {
    max-width: 90%;
  }

`;

const Container = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding: 10px;
  padding-top: 20px;
  min-height: 200px;
  margin-top: -45px;
  @media screen and (max-width: 991px) {
    width: 90%;
  }
`;

const Hr = styled.div`
  margin-top: 15px;
  margin-bottom: 12px;
  text-align: center;
  position: relative;
  span {
    color: #000000;
    font-size: 18px;
    background-color: #f0ebf8;
    position: relative;
    z-index: 11;
    padding-left: 15px;
    padding-right: 15px;
    @media screen and (max-width: 991px) {
      font-size: 20px;
    }
  }
  :after {
    content: "";
    height: 2px;
    width: 100%;
    background: #707070;
    position: absolute;
    left: 0;
    top: 50%;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  @media screen and (max-width: 991px) {
    flex-direction: column;
  }
`;

const Col = styled.div`
  width: 33.33%;
  @media screen and (max-width: 991px) {
    width: 100%;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 991px) {
    justify-content: center;
    display: flex;
  }
  &:nth-child(2) {
    display: flex;
    justify-content: center;
  }
  &:last-child {
    display: flex;
    justify-content: flex-end;
    @media screen and (max-width: 991px) {
      display: flex;
      justify-content: center;
    }
  }
`;

const Card = styled.div`
  background: #ffffff;
  -webkit-box-shadow: 6px 0px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 6px 0px 6px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 6px 0px 6px 0px rgba(0, 0, 0, 0.16);
  max-width: 260px;
  width: 100%;
  padding: 14px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  div {
    min-height: 30px;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 53px;
    }
  }
  h2 {
    width: auto;
    display: flex;
    font-size: 18px;
    color: #707070;
    font-weight: normal;
    text-align:  center;
    margin-top: auto;
  }
`;

const Details = () => {
  useContext(RecordWebCamContext);
  const { setData } = useContext(InterviewContext);
  return (
    <div className="mt-20">
      <Wrapper>
        <Logo>
          <img src={logoimg} alt="logo" />
        </Logo>
        <Container>
          <TabsBlock setData={setData} />
          <Hr>
            <span>Let's get started!</span>
          </Hr>
          <Row>
            <Col>
              <Card>
                <div>
                  <img src={Information} alt="information" />
                </div>
                <h2> Just put in your information</h2>
              </Card>
            </Col>
            <Col>
              <Card>
                <div>
                  <img src={Questions} alt="questions" />
                </div>
                <h2>Answer the questions as best as you can</h2>
              </Card>
            </Col>
            <Col>
              <Card>
                <div>
                  <img src={Said} alt="said" />
                </div>
                <h2>Take a look back at what you said</h2>
              </Card>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    </div>
  );
};

export default Details;
