import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const notify = (message, type) => {
  toast(message, {
    type: type,
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "colored",
  });
};

export default notify;
