import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { AuthContext } from '../context/AuthContext';

const LogoutButton = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await firebase.auth().signOut();
      navigate('/auth/login');
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  if (!currentUser) return null;

  return <button onClick={handleLogout}>Logout</button>;
};

export default LogoutButton;
