import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Carousel } from "../components/";
import { Analysis } from "./";
import { InterviewContext } from "../context/InterviewContext";

const Evaluation = () => {
  const navigate = useNavigate();
  const [showAnalysis, setShowAnalysis] = useState(false);
  const [gotRate, setGotRate] = useState(false);
  const { evaluation, analysis, topic } = useContext(InterviewContext);

  useEffect(() => {
    setTimeout(() => {
      if (evaluation?.length === 0) navigate("/");
    }, 1500);
  });

  return (
    <div className="w-full max-h-screen p-2 mt-20">
      {!showAnalysis ? (
        <Carousel />
      ) : (
        <Analysis analysis={analysis} topics={topic} />
      )}
    </div>
  );
};

export default Evaluation;
