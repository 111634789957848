import React, { useContext, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import "firebase/compat/auth";
import { AuthContext } from "./context/AuthContext";
import { InterviewContext } from "./context/InterviewContext";
import { Details, Questions, Evaluation, AuthRoutes } from "./pages/";
import LogoutButton from './components/LogoutButton';
import './App.css';


import Webcam from "./context/Webcam";
import { ThemeProvider } from "styled-components";
import { ToastContainer } from "react-toastify";
import customTheme from "./themes/theme";
import ProtectedRoute from "./ProtectedRoute";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useContext(AuthContext);
  const { data, setEvaluation, answers, setAnswers, setQuestions, analysis, setAnalysis, topic } = useContext(InterviewContext);

  useEffect(() => {
    if (!currentUser && location.pathname !== "/auth/login") {
      navigate("/auth/login");
    } else if (currentUser && location.pathname === "/auth/login") {
      navigate("/");
    }
  }, [currentUser, navigate, location.pathname]);


  return (
    <ThemeProvider theme={customTheme}>
      <Webcam>
        <LogoutButton />
        <Routes>
          <Route path="/" element={
            <ProtectedRoute>
              <Details />
            </ProtectedRoute>
          } />
          <Route path="/questions" element={
            <ProtectedRoute>
              <Questions data={data} setEvaluation={setEvaluation} setAnswers={setAnswers} setQuestions={setQuestions} />
            </ProtectedRoute>
          } />
          <Route path="/evaluation" element={
            <ProtectedRoute>
              <Evaluation Evaluation={data} answers={answers} analysis={analysis} topic={topic} setAnalysis={setAnalysis} />
            </ProtectedRoute>
          } />
          <Route path="/auth/*" element={<AuthRoutes />} />
        </Routes>
      </Webcam>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </ThemeProvider>
  );
}

export default App;
