import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import logoimg from "./../assets/logo.png";
import Vtab from "./vtab";
import { InterviewContext } from "../context/InterviewContext";

const Wrapper = styled.div`
  width: auto;
  background: #f0ebf8;
  min-height: auto;
  box-shadow: inset 2px -10px 5px 0px rgb(0 0 0 / 11%);
  margin: -2.5rem 3rem;
  border-radius: 50px;
`;

const Logo = styled.div`
  width: 200px;
  height: 60px;
  background-color: #fff;
  border-radius: 0 40px 40px 0;
  top: -20px;
  position: relative;
  img {
    max-width: 90%;
  }
`;

const Container = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding: 10px;
  padding-top: 20px;
  min-height: 400px;
  margin-top: -35px;
  @media screen and (max-width: 991px) {
    width: 90%;
    font: auto;
  }

`;
const Question = styled.div`
    width: auto;
    background-color: #fff;
    border: solid 5px #4500F4;
    border-radius: 40px;
    min-height: 400px;
    box-shadow: 6px 0px 6px 0px rgb(0 0 0 / 16%);
    padding: 10px;
    min-height: 300px;
    h2 {
      text-align:center;
      margin:0;
      font-size: 18px;
      font-weight:normal;
      margin-bottom:10px;
    }
    p {
       text-align:center;
       margin-bottom:18px; 
       margin-top: 5px;
       font-weight:bold;
       font-size:18px;
    }
    @media screen and (max-width: 991px) { 
      margin: 20px;
      padding: 10px;
   }
`;
 

const Carousel = () => {
    const [index, setIndex] = useState(0);
    const [analysisButton, setAnalysisButton] = useState(false);
    const { data } = useContext(InterviewContext);
  
    useEffect(() => {
      if (index === data.length - 1) setAnalysisButton(true);
      else setAnalysisButton(false);
    },[index, data.length]);


  return (
      <Wrapper>
        <Logo>
          <img src={logoimg} alt="logo" />
        </Logo>
        <Container>
          <Question>
            <h2>Question {index + 1}</h2>
            <p>{data[index]?.question}</p>
            <Vtab index={index} setIndex={setIndex} analysisButton={analysisButton} setAnalysisButton={setAnalysisButton} />
          </Question>
        </Container>
      </Wrapper>
  );
};

export default Carousel;
