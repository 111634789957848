import React, { useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import app from "../../../firebase/firebase";
const LoginForm = () => {
    useEffect(() => {
        const ui =
            firebaseui.auth.AuthUI.getInstance() ||
            new firebaseui.auth.AuthUI(firebase.auth(app));
        const uiConfig = {
            signInSuccessUrl: "/",
            signInOptions: [
                {
                    provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                    clientId: "57225589442-d40vdrtb8pd75jb94vh989dirtmv77gk.apps.googleusercontent.com",
                },
                {
                    provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                },
            ],
            credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
            callbacks: {
                signInSuccessWithAuthResult: async (authResult, redirectUrl) => {
                    return true;
                },
                signInFailure: (error) => {
                    console.error('Sign-in failed:', error);
                    return Promise.resolve();
                },
            },
        };

        ui.start("#firebaseui-auth-container", uiConfig);
    }, []);

    return (
        <div id="firebaseui-auth-container"></div>
    );
}

export default LoginForm;
