import "react-toastify/dist/ReactToastify.css";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD_BZengZJYxj8x_3j2K5Y0uR4J2bpT8dw",
  authDomain: "mockpi.firebaseapp.com",
  projectId: "mockpi",
  storageBucket: "mockpi.appspot.com",
  messagingSenderId: "57225589442",
  appId: "1:57225589442:web:050fee6bd922674890415b",
  measurementId: "G-8TJ1TERVLH"
};

const app = firebase.initializeApp(firebaseConfig);

export default app;
