import React, { useState, useContext } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import styled from "styled-components";
import Critical from "./../assets/Critical.svg";
import Answer from "./../assets/Answer.svg";
import Improvement from "./../assets/Improvement.svg";
import Arrow from "./../assets/arrow.svg";
import RecordWebCamContext from "../context/WebcamContext";
import { InterviewContext } from "../context/InterviewContext";
import notify from "../utils/notify";
import { toast } from "react-toastify";
import BasicModal from "./BasicModal";

const TabAction = styled.div`
  width: auto;
  display: flex;
  justify-content: center;
  margin-top:0.5px;
  margin-buttom:10px;
  @media screen and (max-width: 991px) {
    flex-direction: column;
    max-width: 300px;
    margin: 30px auto 30px;
  }
  .button {
    width: auto;
    margin-left: 10px;
    @media screen and (max-width: 991px) {
      margin-left: 0;
      margin-bottom: 10px;
    }
  }
`;

export default function Vtab({
  index,
  setIndex,
  analysisButton,
  setAnalysisButton
}) {
  const [currentTab, setCurrentTab] = useState(0);
  const recordWebcam = useContext(RecordWebCamContext);
  const [showAnalysis, setShowAnalysis] = useState(false);
  const [open, setOpen] = useState(false);
  const { data, answers, questions, analysis, setAnalysis, evaluation } = useContext(InterviewContext);

  const handleNext = () => {
    const newIndex = index + 1;
    setIndex((prev) => {
      if (newIndex > data?.length - 1) return prev;
      else prev = newIndex;
      return prev;
    });
  };

  const handleShowAnalysis = async (e) => {
    e.preventDefault();
    setShowAnalysis(true);

    try {
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/grade`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          answers: answers,
          questions: questions
        }),
      })
      const datas = await res.json();
      setAnalysis(datas)

    }
    catch (err) {
      notify(toast, err.message, "error");
    }
  }

  const handlePrev = () => {
    const newIndex = index - 1;
    setIndex(newIndex < 0 ? 0 : newIndex);
    if (newIndex === data?.length - 2) setAnalysisButton(false);
  };

  return (
    <div>
      <Tabs
        selectedIndex={currentTab}
        onSelect={(index) => setCurrentTab(index)}
      >
        <TabList id="controlled-tab-example" defaultindex={0}>
          <Tab>
            <div className="tab">
              <div className="icon-block">
                <div className="arrow">
                  <img src={Arrow} alt="Critical" />
                </div>
                <div className="icon">
                  <img src={Critical} alt="Critical" />
                </div>
              </div>
              <p>Critical Comment</p>
            </div>
          </Tab>
          <Tab>
            <div className="tab">
              <div className="icon-block">
                <div className="arrow">
                  <img src={Arrow} alt="Critical" />
                </div>
                <div className="icon">
                  <img src={Improvement} alt="Improvement" />
                </div>
              </div>

              <p>Areas of Improvement</p>
            </div>
          </Tab>
          <Tab>
            <div className="tab">
              <div className="icon-block">
                <div className="arrow">
                  <img src={Arrow} alt="Critical" />
                </div>
                <div className="icon">
                  <img src={Answer} alt="Answer" />
                </div>
              </div>
              <p>Reference Answer</p>
            </div>
          </Tab>
        </TabList>
        <TabPanel>
          <div className="panel-content">
            <h2>Critical Comment</h2>
            <div className="panel-info">
              <p className="text-white text-lg mt-2">{evaluation[index]?.criticalComment}</p>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="panel-content">
            <h2>Areas of Improvement</h2>
            <div className="panel-info">
              <p className="text-white text-lg mt-2">{evaluation[index]?.areaOfImprovement}</p>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="panel-content">
            <h2>Reference Answer</h2>
            <div className="panel-info">
              <p className="text-white text-lg mt-2">{evaluation[index]?.reference}</p>
            </div>
          </div>
        </TabPanel>
      </Tabs>

      <TabAction>
        <button
          className="button"
          onClick={handlePrev}
        >
          Previous
        </button>
        {analysisButton ? (
          <button
            className="button"
            onClick={handleShowAnalysis}
          >
            Show Analysis
          </button>
        ) : (
          <button
            className="button"
            onClick={handleNext}
          >
            Next
          </button>
        )}
      </TabAction>
      {showAnalysis ? <BasicModal content={analysis} open={showAnalysis} setOpen={setShowAnalysis} />: <></>}
    </div>
  );
}
