import React, { useEffect, useState, useContext } from "react";
import logoimg from "./../assets/logo.png";
import RecordWebCamContext from "../context/WebcamContext";
import "react-circular-progressbar/dist/styles.css";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import final_logo from '../assets/analysis/Logo_Final.png';
import Confetti from 'react-dom-confetti';
import { InterviewContext } from "../context/InterviewContext";


const Wrapper = styled.div`
  width: auto;
  height: 660px;
  background: #f0ebf8;
  box-shadow: inset 2px -10px 5px 0px rgb(0 0 0 / 11%);
  margin: -3rem 3rem;
  border-radius: 50px;
`;

const Logo = styled.div`
  width: 200px;
  height: 60px;
  background-color: #fff;
  border-radius: 0 40px 40px 0;
  top: -20px;
  position: relative;
  img {
    max-width: 90%;
  }

`;


const Analysis = () => {
  const [today, setToday] = useState("");
  const [confettiActive, setConfettiActive] = React.useState(false);
  const [isReveal, setIsReveal] = useState(false);
  const recordWebcam = useContext(RecordWebCamContext);
  const { analysis, topics } = useContext(InterviewContext);
  const navigate = useNavigate();


  const topic = localStorage.getItem("topic")
    ? localStorage.getItem("topic")
    : "";

  useEffect(() => {
    let d = new Date();
    let date = d.getDate();
    let month = d.getMonth() + 1;
    let year = d.getFullYear();
    setToday(date + "/" + month + "/" + year);
    return () => {
      recordWebcam.stop();
    }; // to stop recording
  }, []);


  const handleShare = () => {
    // Check if the share API is supported in the current browser
    if (navigator.share) {
      navigator.share({
        title: 'My Interview Score',
        text: `I scored ${analysis.overall_grade} in my interview! Check out my analysis.`,
        url: window.location.href
      })
        .then(() => console.log('Successfully shared'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
      console.log('Share API not supported in this browser');
    }
  };
  console.log(analysis)
  // Function to handle button click
  const handleClick = (() => {
    setIsReveal(true);
    if (analysis.overall_grade === "A" || analysis.overall_grade === "A+") {
      setConfettiActive(true);

      // Hide confetti after 3 seconds
      setTimeout(() => {
        setConfettiActive(false);
      }, 3000);
    }
  });

  // Configuration settings for confetti
  const config = {
    angle: 80,
    spread: 400,
    startVelocity: 100,
    elementCount: 400,
    dragFriction: 0.15,
    duration: 4000,
    stagger: 2,
    width: '10px',
    height: '10px',
    colors: ['#4500F2', '#FFBB00', '#00E676'],
  };

  return (
    <>
      <div className="-mt-2">
        <Wrapper>
          <Logo>
            <img src={logoimg} alt="logo" />
          </Logo>
          <div className="w-[100%] h-[95%] grid grid-cols-2">
            <div className="">
              <div className="bg-analysis3 z-10 w-[80%] h-[90%] bg-cover mx-auto">
                <div className="w-[100%] h-[20%] flex flex-col justify-center items-center">
                  <div className="flex flex-row justify-center items-center">
                    <img src={final_logo} alt="" className="w-10 h-10" />
                    <h4 className="text-[#707070] my-5 w-auto">Report Card</h4>
                  </div>
                  <div className="flex justify-evenly items-center w-[100%]">
                    <p className="bg-[#EBE8F3] rounded-xl p-1 mx-w-[120px] h-[30px] text-sm text-center">
                      {today}
                    </p>
                    <p className="bg-[#EBE8F3] rounded-xl p-1 mx-w-[120px] h-[30px] text-sm text-center">
                      MockNo.01
                    </p>
                    <p className="bg-[#EBE8F3] rounded-xl p-1 w-[120px] h-[30px] text-sm text-center">
                      {topic}
                    </p>
                  </div>
                </div>
                <div className="w-[100%] h-[45%] flex flex-row">
                  <div className="w-[40%] my-6">
                    <div className="bg-analysis2 w-[66%] h-[70%] my-[30px] mx-auto bg-contain bg-no-repeat flex items-center justify-center">
                      {isReveal && <h1 className="text-white">{analysis.overallGrade}</h1>}
                      <Confetti active={confettiActive} config={config} />
                    </div>
                  </div>
                  <div className="my-[30px] w-[60%] items-center flex flex-col justify-center">
                    {isReveal &&
                      (<>
                        <p className="font-extrabold text-lg mx-4 text-center">{analysis.headings}</p>
                        <p className="text-center text-sm">{analysis.comments}</p>
                      </>)}
                    {isReveal ? <></> : <button className="rounded-2xl p-2 bg-[#4500F2] text-sm my-3  text-white" onClick={handleClick}>Reveal Score</button>}

                  </div>
                </div>
                <div className="h-[30%] flex flex-row">
                  <div className=" w-[45%] mx-2 items-center flex flex-col justify-center">
                    <p className="font-light text-sm text-center">watch yourself in action and</p>
                    <p className="font-light text-sm text-center">unlock your full</p>
                    <p className="font-extrabold text-center">Interview Potential</p>
                    <button className="rounded-2xl w-[100px] bg-[#4500F2] text-md my-1 p-1 text-white" onClick={handleShare} >share</button>
                    <button className="rounded-2xl bg-[#4500F2] text-md w-[100px] p-1 text-white" onClick={() => navigate("/")}>New Mock</button>
                  </div>
                  <div className="w-[55%]">
                    {recordWebcam.previewRef && (
                      <div className="w-[80%] h-[90%] mx-auto">
                        <video
                          ref={recordWebcam.previewRef}
                          autoPlay
                          muted
                          loop
                          controls
                          className="w-full h-full rounded-2xl"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

            </div>
            <div className="">
              <div className="bg-analysis5 bg-cover bg-no-repeat w-[83%] h-[90%] left-[8px] mx-auto flex flex-col items-center justify-center">
                {/* lock feature */}
                <p className="">check your</p>
                <p className="font-bold">Video Analytics <span className="font-light">and</span></p>
                <p className="font-bold">Sentiment Detection</p>
                <button className="rounded-2xl p-1 w-[30%] bg-[#4500F2] text-white">Coming your Way</button>

                {/* Unlock Feature */}
                {/* <div className="w-[100%] h-[20%] flex flex-col justify-center items-center">
                  <div className="flex flex-row justify-center items-center">
                    <h4 className="absolute top-[86px] left-[1000px] text-[#707070] inline-block w-[387px]">
                      Interview Analytics
                    </h4>
                  </div>
                  <div className="bg-border1 absolute top-[130px] left-[850px] w-[340px] h-[136px] items-center justify-center">
                    <p className="font-extrabold text-lg mx-4 text-center">Confidence</p>
                    <div className="bg-confi1 absolute top-[35px] left-[-2px] w-[17%] h-[60px]">

                      <div className="absolute top-[66px] left-[27px] text-mini leading-[20.43px] font-semibold inline-block w-[54px] h-[39px]">
                        1
                      </div>
                    </div>
                    <div className="bg-confi2 absolute top-[35px] left-[70px] w-[60px] h-[60px]">

                      <div className="absolute top-[66px] left-[27px] text-mini leading-[20.43px] font-semibold inline-block w-[54px] h-[39px]">
                        2
                      </div>
                    </div>
                    <div className="bg-confi3 absolute top-[35px] left-[145px] w-[63px] h-[61px]">

                      <div className="absolute top-[66px] left-[27px] text-mini leading-[20.43px] font-semibold inline-block w-[54px] h-[39px]">
                        3
                      </div>
                    </div>
                    <div className="bg-confi4 absolute top-[35px] left-[225px] w-[65px] h-[60px]">

                      <div className="absolute top-[66px] left-[27px] text-mini leading-[20.43px] font-semibold inline-block w-[54px] h-[39px]">
                        4
                      </div>
                    </div>
                    <div className="bg-confi5 absolute top-[35px] left-[305px] w-[65px] h-[60px]">

                      <div className="absolute top-[66px] left-[27px] text-mini leading-[20.43px] font-semibold inline-block w-[54px] h-[39px]">
                        5
                      </div>
                    </div>

                    <div className="bg-percentage absolute top-[130px] left-[5px] w-[366px] h-[15px]">
                      <div className="bg-elipse absolute top-[-8px] left-[137px] w-8 h-8">
                      </div>
                    </div>
                  </div>
                  <div className="bg-border1 absolute top-[280px] left-[850px] w-[340px] h-[136px] items-center justify-center">
                    <p className="font-extrabold text-lg mx-4 text-center">Smile</p>
                    <div className="bg-confi1 absolute top-[35px] left-[-2px] w-[61px] h-[60px]">

                      <div className="absolute top-[66px] left-[27px] text-mini leading-[20.43px] font-semibold inline-block w-[54px] h-[39px]">
                        1
                      </div>
                    </div>
                    <div className="bg-confi2 absolute top-[35px] left-[70px] w-[60px] h-[60px]">

                      <div className="absolute top-[66px] left-[27px] text-mini leading-[20.43px] font-semibold inline-block w-[54px] h-[39px]">
                        2
                      </div>
                    </div>
                    <div className="bg-confi3 absolute top-[35px] left-[145px] w-[63px] h-[61px]">

                      <div className="absolute top-[66px] left-[27px] text-mini leading-[20.43px] font-semibold inline-block w-[54px] h-[39px]">
                        3
                      </div>
                    </div>
                    <div className="bg-confi4 absolute top-[35px] left-[225px] w-[65px] h-[60px]">

                      <div className="absolute top-[66px] left-[27px] text-mini leading-[20.43px] font-semibold inline-block w-[54px] h-[39px]">
                        4
                      </div>
                    </div>
                    <div className="bg-confi5 absolute top-[35px] left-[305px] w-[65px] h-[60px]">

                      <div className="absolute top-[66px] left-[27px] text-mini leading-[20.43px] font-semibold inline-block w-[54px] h-[39px]">
                        5
                      </div>
                    </div>
                    <div className="bg-percentage absolute top-[130px] left-[5px] w-[366px] h-[15px]">
                      <div className="bg-elipse absolute top-[-8px] left-[307px] w-8 h-8">
                      </div>
                    </div>
                  </div>
                  <div className="bg-border2 absolute top-[130px] left-[1240px] w-[175px] h-[296px] items-center justify-center">

                  </div>
                </div> */}

              </div>
            </div>
          </div>

        </Wrapper>
      </div>
    </>
  )
};

export default Analysis;
