import React from "react";
import "firebase/compat/auth";
import LoginForm from './LoginForm';
import '../../../css/typography.css';
import '../../../css/style.css';
import '../../../css/responsive.css';
import "react-toastify/dist/ReactToastify.css";
import RightImage from "../../../assets/login/login-img.gif";
import Fluid1 from "../../../assets/login/1.png";
import Fluid2 from "../../../assets/login/2.png";
import Fluid3 from "../../../assets/login/3.png";

const Login = () => {
  return (
    <div className="login-from header-navbar light-gray-bg w-auto position-relativr sm:text-left">
      {/* <Navbar/> */}
      <div className="row no-gutters flex items-flex w-auto">
        <div className="col-lg-6 align-items-stretch position-relative white-bg">
          <div className="login-info">
            <h2 className="iq-fw-9 mb-3 font-bold text-2xl mb:responsive">Login</h2>
            <h6>Welcome to <span className="main-color">MockPI</span> <br />please login to access the service.</h6>
          </div>
        </div>
        <LoginForm />
        <div className="col-lg-6 flex justify-center items-center mx-10 position-relative">
          <div className="login-right-bar h-100 text-center">
            <img src={RightImage} className="img-fluid h-[100%] w-auto" alt="" />
          </div>
        </div>
      </div>
      <img src={Fluid1} className="img-fluid login-footer-one" alt="" />
      <img src={Fluid2} className="img-fluid login-footer-two" alt="" />
      <img src={Fluid3} className="img-fluid login-footer-three" alt="" />
    </div>
  );
};

export default Login;
