import React, { useEffect, useState, createContext } from 'react';
import firebase from 'firebase/compat/app';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [pending, setPending] = useState(true);

    const generateToken = async () => {
        try {
            const user = firebase.auth().currentUser;
            const idToken = await user.getIdToken(true);
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/generateToken`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ idToken }),
            });
            const data = await response.json();
            localStorage.setItem('token', data.token);
        } catch (error) {
            console.error('Error generating token:', error);
        }
    };

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                setCurrentUser(user);
                generateToken();
            } else {
                setCurrentUser(null);
                localStorage.removeItem('token');
            }
            setPending(false);
        });

        return () => unsubscribe();
    }, []);

    if (pending) {
        return <>Loading...</>;
    }

    return (
        <AuthContext.Provider
            value={{
                currentUser
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
